html * {
  box-sizing: border-box;
}

/* path for beneficiary tree */
.custom-path {
  fill: none !important;
  /* GRAY[300] */
  stroke: #d1d5db !important;
  stroke-width: 2.5px !important;
}
